import { fetchPhotoRatingSettingsAction } from 'actions/photoRating/photoRatingSettingsAction'
import { fetchVotingPhotosAction } from 'actions/rating/fetchVotingPhotosAction'
import { AsyncThunkAction } from 'actions/types'
import { Api6Code, Api6Error } from 'api/api.types'
import { metaphorSlideLimitNoVip } from 'common-constants/metaphor'
import { limitExceededErrorCode } from 'components/page/PhotoRating/constants'
import { storeFrontVipPath } from 'components/storefront/StoreFront.paths'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { push } from 'functions/router'

export const GET_COORDS = 'GET_COORDS'

export const reloadVotingPhotosAction = (): AsyncThunkAction => (dispatch) => {
  dispatch(clearVotingPhotosAction())
  dispatch(fetchVotingPhotosWithSettingsAction())
}

export const fetchVotingPhotosWithSettingsAction = (): AsyncThunkAction => async (
  dispatch
) => {
  await dispatch(fetchPhotoRatingSettingsAction())
  await dispatch(fetchVotingPhotosAction())
}

export const CLEAR_VOTING_PHOTOS = 'CLEAR_VOTING_PHOTOS'

interface ClearVotingPhotosAction {
  type: typeof CLEAR_VOTING_PHOTOS
}

export const clearVotingPhotosAction = () => ({
  type: CLEAR_VOTING_PHOTOS,
})

export const REVERT_LAST_PHOTO = 'REVERT_LAST_PHOTO'

interface RevertLastPhotoAction {
  type: typeof REVERT_LAST_PHOTO
}

export const revertLastPhotoAction = () => ({
  type: REVERT_LAST_PHOTO,
})

export const TOGGLE_TUTORIAL_VISIBLE = 'TOGGLE_TUTORIAL_VISIBLE'

interface ToggleTutorialVisibleAction {
  type: typeof TOGGLE_TUTORIAL_VISIBLE
  isTutorialVisible: boolean
}

export const toggleTutorialVisibleAction = (isTutorialVisible: boolean) => ({
  type: TOGGLE_TUTORIAL_VISIBLE,
  isTutorialVisible,
})

export const handleRatingErrorAction = (
  response: Api6Error
): AsyncThunkAction => (dispatch, getState) => {
  const { baseUrl } = getState().systemReducer
  const { code } = response

  if (code === limitExceededErrorCode) {
    dispatch(
      push(mergeAllUrls(baseUrl, storeFrontVipPath, metaphorSlideLimitNoVip))
    )
  } else if (code !== Api6Code.captcha) {
    /**
     * Если вдруг окажется что прошедшие фотки исчезли из рейтинга,
     * то очень часто будет перезагружаться.
     */
    if (code !== Api6Code.PhotoNotFoundInRating) {
      dispatch(reloadVotingPhotosAction())
    }
  }

  // Нужно вернуть для обработки ошибок, например, каптчу
  // https://redmine.mamba.ru/issues/114190
  return response
}

export type RatingTypes =
  | ClearVotingPhotosAction
  | RevertLastPhotoAction
  | ToggleTutorialVisibleAction
