import { UniNoticeId } from 'api/uninotice/uniNoticeApi'
import { NotificationType } from 'components/presentational/Notifications/NotificationType'
import { NotificationItem } from 'reducers/notificationReducer'

export const PUSH_NOTIFICATION = 'PUSH_NOTIFICATION'
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION'

interface PushNotificationAction {
  type: typeof PUSH_NOTIFICATION
  notification: NotificationItem
}

let notificationId = 0

export const pushNotificationAction = (
  notificationType: NotificationType | string,
  id: UniNoticeId = `notification-${++notificationId}`
) => ({
  type: PUSH_NOTIFICATION,
  notification: {
    id,
    type: notificationType,
  },
})

interface RemoveNotificationAction {
  type: typeof REMOVE_NOTIFICATION
  id: UniNoticeId
}

export const removeNotificationAction = (id: UniNoticeId) => ({
  type: REMOVE_NOTIFICATION,
  id,
})

export type NotificationActionTypes =
  | PushNotificationAction
  | RemoveNotificationAction
