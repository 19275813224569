import { AsyncAction, AsyncThunkAction } from 'actions/actions.types'
import { toggleProfileUpdateAction } from 'actions/profile/toggleProfileUpdateAction'
import { goBackAction } from 'actions/route/routeAction'
import { fetchUniNoticeAction } from 'actions/uninotice/uniNoticeAction'
import { apiResultHasErrors } from 'api/function/apiResultHasErrors'
import { DeprecatedApi6Error } from 'api/types'
import {
  confirmUpdateUserBirthDateApi,
  fetchAllowedChangeBirthDateApi,
} from 'api/user/field/birthDateApi'
import { UniNoticeId } from 'components/uninotice/uninotice.types'
import { concatBirthDate } from 'functions/getObjectFromDate'
import { replace } from 'functions/router'

export const UPDATE_USER_BIRTHDATE = 'UPDATE_USER_BIRTHDATE'

interface UpdateUserBirthDateAction {
  type: typeof UPDATE_USER_BIRTHDATE
  name: string
  value: number
}

export const updateUserBirthDateAction = (name: string, value: number) => ({
  type: UPDATE_USER_BIRTHDATE,
  name,
  value,
})

export const FETCH_USER_BIRTHDATE = 'FETCH_USER_BIRTHDATE'

interface FetchUserBirthDateAction {
  type: typeof FETCH_USER_BIRTHDATE
  date: string
}

export const fetchUserBirthDateAction = (
  date: string
): FetchUserBirthDateAction => ({
  type: FETCH_USER_BIRTHDATE,
  date,
})

export const FETCH_ALLOWED_CHANGE_BIRTHDATE = 'FETCH_ALLOWED_CHANGE_BIRTHDATE'

interface FetchAllowedChangeBirthDateAction
  extends AsyncAction<
    Awaited<ReturnType<typeof fetchAllowedChangeBirthDateApi>>['result']
  > {
  type: typeof FETCH_ALLOWED_CHANGE_BIRTHDATE
}

export const fetchAllowedChangeBirthDateAction = (): FetchAllowedChangeBirthDateAction => ({
  type: FETCH_ALLOWED_CHANGE_BIRTHDATE,
  promise: () => fetchAllowedChangeBirthDateApi(),
})

export const CONFIRM_UPDATE_USER_BIRTHDATE = 'CONFIRM_UPDATE_USER_BIRTHDATE'

interface ConfirmUpdateUserBirthDateAction
  extends AsyncAction<
    Awaited<ReturnType<typeof confirmUpdateUserBirthDateApi>>
  > {
  type: typeof CONFIRM_UPDATE_USER_BIRTHDATE
}

export const confirmUpdateUserBirthDateAction = (): AsyncThunkAction => (
  dispatch,
  getState
) => {
  const {
    date: { day, month, year },
  } = getState().birthDate
  const dateString = concatBirthDate(day, month, year)

  return dispatch({
    type: CONFIRM_UPDATE_USER_BIRTHDATE,
    promise: () => confirmUpdateUserBirthDateApi(dateString),
  })
}

export const confirmUpdateUserBirthDateUniNoticeAction = (): AsyncThunkAction => async (
  dispatch,
  getState
) => {
  const { result } = ((await dispatch(
    confirmUpdateUserBirthDateAction()
  )) as unknown) as { result: DeprecatedApi6Error }
  const baseUrl = getState().systemReducer.baseUrl

  if (!apiResultHasErrors(result)) {
    dispatch(toggleProfileUpdateAction(true))
    return dispatch(replace(baseUrl))
  }

  return dispatch(goBackAction())
}

export const switchConfirmBirthDateAction = (): AsyncThunkAction => (
  dispatch,
  getState
) => {
  const {
    date: { day, month, year },
    dateDefault,
  } = getState().birthDate
  const dateString = concatBirthDate(day, month, year)

  if (dateDefault === dateString) {
    return dispatch(goBackAction())
  }

  return dispatch(
    fetchUniNoticeAction(UniNoticeId.dating_profile_confirm_birthdate_change)
  )
}

export type BirthDateAction =
  | UpdateUserBirthDateAction
  | FetchUserBirthDateAction
  | FetchAllowedChangeBirthDateAction
  | ConfirmUpdateUserBirthDateAction
