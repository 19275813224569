import { AppDispatchNext, AppGetState } from 'actions/actions.typesNext'

import { getCurrentPositionAction } from './getCurrentPositionAction'

export const getCoordinatesAction = () => async (
  dispatch: AppDispatchNext,
  getState: AppGetState
) => {
  const {
    geolocation: { coordinates },
  } = getState()

  return coordinates || dispatch(getCurrentPositionAction())
}
