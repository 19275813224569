import { settingsVerificationPath } from 'components/page/Settings/Settings.paths'
import { verifyByPhotoPath } from 'components/page/Settings/Verification/Verification.paths'
import {
  albumPath,
  uploadPhotosPath,
} from 'components/page/UploaderPhotos/Uploader.paths'
import { inlineNoticePath, settingsModalPath } from 'components/paths'
import { UniNoticeId } from 'components/uninotice/uninotice.types'
import { mergeAllUrls } from 'functions/mergeAllUrls'

export const switchLinkPhotoFilterVerification = (
  baseUrl: string,
  hasDefaultPhoto: boolean | null,
  isProfileBanned?: boolean
) => {
  const uploadPhotoPath = mergeAllUrls(baseUrl, uploadPhotosPath, albumPath)

  if (isProfileBanned) {
    return mergeAllUrls(
      inlineNoticePath,
      UniNoticeId.userBanned,
      settingsModalPath,
      settingsVerificationPath,
      verifyByPhotoPath
    )
  }

  if (hasDefaultPhoto) {
    return mergeAllUrls(
      baseUrl,
      settingsModalPath,
      settingsVerificationPath,
      verifyByPhotoPath
    )
  }

  return uploadPhotoPath
}
