import { ApiResult } from 'api/fetchApi'
import { resetSearchSettingsApi } from 'api/mainSearchApi'
import { addInterestInSearchOptionsApi } from 'api/search/addInterestInSearchOptionsApi'
import { saveSearchSettingListApi } from 'api/search/saveSearchSettingListApi'
import { SearchRequest } from 'api/search/search.types'
import { LocationPosition } from 'api/types'
import { DefaultSearchForm } from 'components/page/MainSearch/defaultSearchForm'
import { singleSelectField } from 'components/page/MainSearch/FormFieldsStatic'
import { goBack } from 'functions/router'

import { AsyncThunkAction } from './actions.types'

export const RESET_SEARCH_LIST = 'RESET_SEARCH_LIST' as const
export const resetSearchListAction = () => ({
  type: RESET_SEARCH_LIST,
})

export const RESET_INITIAL_STATE = 'RESET_INITIAL_STATE' as const
export const resetInitialStateAction = () => ({
  type: RESET_INITIAL_STATE,
})

export const RESET_CURRENT_FORM = 'RESET_CURRENT_FORM' as const
export const resetCurrentFormAction = () => ({
  type: RESET_CURRENT_FORM,
})

export const UPDATE_SEARCH_SETTINGS_FIELD = 'UPDATE_SEARCH_SETTINGS_FIELD' as const
export const updateSearchSettingsFieldAction = (
  field: string,
  name: string,
  value: string | boolean
) => ({
  type: UPDATE_SEARCH_SETTINGS_FIELD,
  field,
  name,
  value,
})

export const UPDATE_SEARCH_SETTINGS_SECONDARY_FIELD = 'UPDATE_SEARCH_SETTINGS_SECONDARY_FIELD' as const
export const updateSearchSettingsSecondaryFieldPlainAction = (
  field: keyof DefaultSearchForm,
  value: string | boolean,
  itemLocation?: LocationPosition
) => ({
  type: UPDATE_SEARCH_SETTINGS_SECONDARY_FIELD,
  field,
  value,
  itemLocation,
})

export const updateSearchSettingsSecondaryFieldAction = (
  field: keyof DefaultSearchForm,
  value: string | boolean,
  itemLocation?: LocationPosition
): AsyncThunkAction => (dispatch) => {
  dispatch(
    updateSearchSettingsSecondaryFieldPlainAction(field, value, itemLocation)
  )

  if (singleSelectField.includes(field)) {
    dispatch(goBack())
  }
}

export const UPDATE_LOOK_FOR_FIELD_SETTINGS = 'UPDATE_LOOK_FOR_FIELD_SETTINGS' as const

export const updateLookForFieldSettingsAction = (
  field: keyof DefaultSearchForm,
  value: string,
  authorized: boolean
) => ({
  type: UPDATE_LOOK_FOR_FIELD_SETTINGS,
  field,
  value,
  authorized,
})

export const UPDATE_LEGACY_LOCATION_FIELD = 'UPDATE_LEGACY_LOCATION_FIELD' as const
export const updateLegacyLocationFieldAction = (location: string) => ({
  type: UPDATE_LEGACY_LOCATION_FIELD,
  location,
})

export const saveSearchSettingsAction = (): AsyncThunkAction => async (
  dispatch,
  getState
) => {
  const { mainSearchReducer } = getState()

  if (!mainSearchReducer) {
    return
  }

  const { form } = mainSearchReducer

  const { result: json } = ((await dispatch(
    saveSettingsMainSearchPlainAction((form as unknown) as SearchRequest)
  )) as unknown) as { result: ApiResult }

  if (!json.error) {
    dispatch(resetSearchListAction())
  }
}

export const SAVE_MAIN_SEARCH_SETTINGS = 'SAVE_MAIN_SEARCH_SETTINGS' as const

export const saveSettingsMainSearchPlainAction = (form: SearchRequest) => ({
  type: SAVE_MAIN_SEARCH_SETTINGS,
  promise: () => saveSearchSettingListApi(form),
})

export const RESET_SEARCHING_SETTINGS = 'RESET_SEARCHING_SETTINGS' as const
export const resetSearchSettingsPlainAction = () => ({
  type: RESET_SEARCHING_SETTINGS,
  promise: () => resetSearchSettingsApi(),
})

export const fullResetSearchFormAction = (): AsyncThunkAction => async (
  dispatch
) => {
  await dispatch(resetSearchSettingsPlainAction())
  dispatch(resetInitialStateAction())
  dispatch(resetSearchListAction())
}

export const ADD_SEARCH_INTEREST = 'ADD_SEARCH_INTEREST' as const
export const addSearchInterestAction = (id: number, text: string) => ({
  type: ADD_SEARCH_INTEREST,
  interest: { id, text },
})

export const REMOVE_SEARCH_INTEREST = 'REMOVE_SEARCH_INTEREST' as const
export const removeSearchInterestAction = (value: number) => ({
  type: REMOVE_SEARCH_INTEREST,
  value,
})

export const ADD_INTEREST_IN_SEARCH_OPTIONS = 'ADD_INTEREST_IN_SEARCH_OPTIONS' as const
export const addInterestInSearchOptionsAction = (interestId: number) => ({
  type: ADD_INTEREST_IN_SEARCH_OPTIONS,
  promise: () => addInterestInSearchOptionsApi(interestId),
})
