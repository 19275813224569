import { findApiLocale } from 'actions/actions.functions'
import { resetSearchListAction } from 'actions/mainSearchAction'
import {
  clearVotingPhotosAction,
  fetchVotingPhotosWithSettingsAction,
} from 'actions/rating/photoRatingAction'
import { AsyncThunkAction } from 'actions/types'
import { definitions } from 'api/generated/location'
import {
  fetchPhotoRatingSettingsListApi,
  fetchPhotoRatingSettingsListNotAuthorizedApi,
  normalizePhotoRatingSettingsApi,
  savePhotoRatingSettingsListApi,
} from 'api/rating/photoRatingSettingsApi'
import { locationStringUnderscore } from 'functions/location/locationStringUnderscore'
import { Form } from 'reducers/rating/PhotoRatingSettingsState'

export const FETCH_PHOTO_RATING_SETTINGS = 'FETCH_PHOTO_RATING_SETTINGS' as const

export const fetchPhotoRatingSettingsAction = (): AsyncThunkAction => (
  dispatch,
  getState
) => {
  const {
    photoRatingSettings: { settingsLoaded },
    authorizationReducer: { authorized },
    systemReducer: { locale: systemLocale },
  } = getState()
  const locale = findApiLocale(authorized, systemLocale)

  if (!settingsLoaded) {
    if (!authorized) {
      return dispatch(fetchPhotoRatingSettingsPlainNotAuthorizedAction(locale))
    }

    return dispatch(fetchPhotoRatingSettingsPlainAuthorizedAction())
  }
}

export const fetchPhotoRatingSettingsPlainNotAuthorizedAction = (
  locale: string
) => ({
  type: FETCH_PHOTO_RATING_SETTINGS,
  promise: () => fetchPhotoRatingSettingsListNotAuthorizedApi(locale),
  critical: true,
})

export const fetchPhotoRatingSettingsPlainAuthorizedAction = () => ({
  type: FETCH_PHOTO_RATING_SETTINGS,
  promise: () => fetchPhotoRatingSettingsListApi(),
  critical: true,
})

export const savePhotoRatingSettingsAction = (): AsyncThunkAction => async (
  dispatch,
  getState
) => {
  const { photoRatingSettings } = getState()

  if (!photoRatingSettings) {
    return
  }

  const { form } = photoRatingSettings

  await dispatch(savePhotoRatingSettingsPlainAction(form))
  /** https://youtrack.mamba.ru/issue/M-8988 */
  return dispatch(resetSearchListAction())
}

export const SAVE_PHOTO_RATING_SETTINGS = 'SAVE_PHOTO_RATING_SETTINGS' as const

const savePhotoRatingSettingsPlainAction = (form: Form) => ({
  type: SAVE_PHOTO_RATING_SETTINGS,
  promise: () =>
    savePhotoRatingSettingsListApi({
      ageFrom: form.ageFrom,
      ageTo: form.ageTo,
      gender: form.gender,
      coordinates: form.coordinates,
      location: locationStringUnderscore([
        form.country,
        form.region,
        form.city,
        form.metro,
      ]),
      heightFrom: form.heightFrom,
      heightTo: form.heightTo,
    }),
})

export const CLEAR_PHOTO_RATING_SETTINGS = 'CLEAR_PHOTO_RATING_SETTINGS' as const

export const clearPhotoRatingSettingsAction = () => ({
  type: CLEAR_PHOTO_RATING_SETTINGS,
})

export const UPDATE_PHOTO_RATING_SETTINGS_FIELD = 'UPDATE_PHOTO_RATING_SETTINGS_FIELD' as const

export const updatePhotoRatingSettingFieldAction = (
  name: string,
  value: string | number | boolean,
  itemLocation?: definitions['NamedLocation'] | null
) => ({
  type: UPDATE_PHOTO_RATING_SETTINGS_FIELD,
  name,
  value,
  itemLocation,
})

export const NORMALIZE_PHOTO_RATING_SETTINGS = 'NORMALIZE_PHOTO_RATING_SETTINGS' as const

export const normalizePhotoRatingSettingsPlainAction = () => ({
  type: NORMALIZE_PHOTO_RATING_SETTINGS,
  promise: () => normalizePhotoRatingSettingsApi(),
})

export const combineNormalizeRestartPhotoRatingAction = (): AsyncThunkAction => async (
  dispatch
) => {
  dispatch(clearVotingPhotosAction())
  /**
   * Нужно дождаться выполнения сброса, чтобы заново получить результаты
   * рейтинга, чтобы не нарваться повторно на notice
   */
  await dispatch(normalizePhotoRatingSettingsPlainAction())
  dispatch(fetchVotingPhotosWithSettingsAction())
}

export const RESET_INITIAL_PHOTO_RATING_FORM = 'RESET_INITIAL_PHOTO_RATING_FORM' as const

export const resetInitialPhotoRatingFormAction = () => ({
  type: RESET_INITIAL_PHOTO_RATING_FORM,
})

export type PhotoRatingsSettingsTypes =
  | ReturnType<typeof fetchPhotoRatingSettingsPlainNotAuthorizedAction>
  | ReturnType<typeof fetchPhotoRatingSettingsPlainAuthorizedAction>
  | ReturnType<typeof updatePhotoRatingSettingFieldAction>
  | ReturnType<typeof clearPhotoRatingSettingsAction>
  | ReturnType<typeof normalizePhotoRatingSettingsPlainAction>
  | ReturnType<typeof savePhotoRatingSettingsPlainAction>
  | ReturnType<typeof resetInitialPhotoRatingFormAction>
